import React from 'react';
import {Button, Card, Col, Container, Row} from 'react-bootstrap';
import {useLocation, useNavigate} from 'react-router';
import {useTranslation} from 'react-i18next';
import {useEcommerce} from '../../contexts/EcommerceContext';

import styles from './StoreRegisterUserSucces.module.scss';

const StoreRegisterUserSuccessPage = () => {
  const navigate = useNavigate();
  const {state} = useLocation();
  const [ecommerceState] = useEcommerce();
  const {t} = useTranslation('pages');

  return (
    <Container fluid className={`px-lg-4 px-xs-2 my-auto ${styles.success}`}>
      <Row>
        <Col xs={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h1">
                {t('StoreRegister.Users.title.success', {username: state?.user_name})}
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <Card.Text>
                {!ecommerceState.settings.COMPANY_APPROVE_USERS
                  ? t('StoreRegister.Users.card.public.allowed.text.success', {email: state?.email})
                  : t('StoreRegister.Users.card.public.restricted.text.success')
                }
              </Card.Text>
              <Card.Text>
                {!ecommerceState.settings.COMPANY_APPROVE_USERS
                  ? t('StoreRegister.Users.card.public.allowed.additional.text.success')
                  : t('StoreRegister.Users.card.public.restricted.additional.text.success', {email: state?.email})
                }
              </Card.Text>
              <Button variant="primary" onClick={() => navigate('/')}>
                {t('StoreRegister.Users.card.navigate.button')}
              </Button>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default StoreRegisterUserSuccessPage;
