import React, {useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {Button} from 'react-bootstrap';
import {FontAwesomeIcon as Icon} from '@fortawesome/react-fontawesome';
import {Route, Routes, useNavigate} from 'react-router';
import {useApi} from '../../contexts/ApiContext';
import {useLayoutTitle} from '../../hooks/layout';
import UsersFormPage from './UsersFormPage';
import DataTable from '../../components/DataTable';
import {useLayout} from '../../contexts/LayoutContext';
import {FILTERABLE_TYPES} from '../../components/DataTable/utils';
import useSearchParamsFilters from '../../hooks/useSearchParamsFilters';
import UsersCustomFieldsPage from '../UsersCustomFieldsPage';
import UsersPendingApprovalPage from '../UsersPendingApprovalPage';
import {useSession} from '../../contexts/SessionContext';
import ProtectedRoutes from '../../components/ProtectedRoutes';

import styles from './UsersPage.module.scss';

const UsersPageIndex = () => {
  const api = useApi();
  const navigate = useNavigate();
  const [, actions] = useLayout();
  const {t} = useTranslation('pages');

  useEffect(() => {
    actions.setTopBar(<CallToActionButtons />);

    return () => {
      actions.setTopBar(null);
    };
  }, [actions]);

  useLayoutTitle({
    label: `${t('UsersPage.title')}`,
    icon: 'fa-solid fa-user',
  });

  const orderChoices = [
    {
      value: 'username',
      label: 'A → Z',
    },
    {
      value: '-username',
      label: 'Z → A',
    },
    {
      value: 'date_joined',
      label: `${t('UsersPage.DataTable.orderChoices.oldest')}`,
    },
    {
      value: '-date_joined',
      label: `${t('UsersPage.DataTable.orderChoices.newest')}`,
    },
  ];

  const columns = [
    {
      Header: `${t('UsersPage.DataTable.columns.headers.username')}`,
      accessor: 'username',
    },
    {
      Header: `${t('UsersPage.DataTable.columns.headers.email')}`,
      accessor: 'email',
    },
    {
      Header: `${t('UsersPage.DataTable.columns.headers.company')}`,
      accessor: 'user_company_name',
    },
    {
      Header: `${t('UsersPage.DataTable.columns.headers.type')}`,
      Cell: ({cell}) => `${t(`UsersPage.DataTable.filterables.type.${cell.value}`)}`,
      accessor: 'type',
    },
  ];

  const buttons = useMemo(
    () => [
      {
        icon: 'fa-solid fa-pencil',
        onClick: (cell) => navigate(`edit/${cell.row.original.id}/`),
        key: 'edit',
      },
      {
        icon: 'fa-solid fa-trash',
        onClick: (cell) => {
          api.put(`/user/${cell.row.original.id}`, {
            username: cell.row.original.username,
            is_active: false,
            type: cell.row.original.type,
          });

          toast.success(
            `${t('UsersPage.DataTable.columns.buttons.delete.message', {
              username: cell.row.original.username,
            })}`,
            {
              hideProgressBar: true,
            },
          );
        },
        key: 'delete',
        refresh: true,
      },
      {
        icon: 'fa-solid fa-store',
        onClick: (cell) => {
          api.impersonate(cell.row.original.id);
          window.open('/', '_blank');
        },
        key: 'impersonate',
      },
    ],
    [navigate, api, t],
  );

  const filterables = [
    {
      field: 'type',
      label: 'Tipo usuario',
      type: FILTERABLE_TYPES.CHOICES,
      choices: [
        {value: 'admin', label: `${t('UsersPage.DataTable.filterables.type.admin')}`},
        {value: 'seller', label: `${t('UsersPage.DataTable.filterables.type.seller')}`},
        {value: 'customer', label: `${t('UsersPage.DataTable.filterables.type.customer')}`},
      ],
    },
  ];

  const {currentFilters, hasFilters} = useSearchParamsFilters(filterables);

  const initialFilters = useMemo(() => {
    const defaultFilters = {approved: true};
    return hasFilters ? currentFilters : defaultFilters;
  }, [currentFilters, hasFilters]);

  return (
    <DataTable
      columns={columns}
      buttons={buttons}
      orderChoices={orderChoices}
      filterables={filterables}
      initialFilters={initialFilters}
      source={{type: 'remote', url: '/user'}}
      allowOrder
      allowSearch
      searchPlaceholder="Busca usuarios..."
    />
  );
};

const CallToActionButtons = () => {
  const navigate = useNavigate();
  const {t} = useTranslation('pages');
  const [, , selectors] = useSession();

  if (selectors.isSellerSession) {
    return null;
  }

  return (
    <div className={styles.ctaButtons}>
      <Button variant="secondary" onClick={() => navigate('/admin/users/add')}>
        <Icon icon="fa-solid fa-user" /> {t('UsersPage.CallToActionButtons.add')}
      </Button>
    </div>
  );
};

const UsersPage = () => {
  const [, , selectors] = useSession();

  return (
    <Routes>
      <Route element={<ProtectedRoutes isAllowed={selectors.isAdminSession} to="/admin/users" />}>
        <Route path="/add" element={<UsersFormPage />} />
      </Route>
      <Route path="/edit/:id" element={<UsersFormPage />} />
      <Route path="/custom-fields" element={<UsersCustomFieldsPage />} />
      <Route path="/pending-approval" element={<UsersPendingApprovalPage />} />
      <Route index element={<UsersPageIndex />} />
      <Route path="/" index/>
    </Routes>
  );
};

export default UsersPage;
